<template>
  <v-theme-provider dark>
    <base-section
      id="contact-us"
      class="primary"
      space="36"
      style="
        text-align: center;
        padding-top: 0px !important;
        background-image: linear-gradient(to right, #aa004c, #bf1051, #d51e56, #ea2b59, #ff385c);
        background: linear-gradient(
          90deg,
          rgb(92, 0, 37) 0%,
          rgb(255, 56, 92) 100%
        );
      "
    >
      <div
        class="triangle-bottomleft"
        :style="
          'border-bottom: ' +
          this.screenCalcTriangleBorderBottom +
          'px solid rgb(170, 0, 76, 0); border-right: ' +
          this.screenWidth +
          'px solid white; margin-bottom: 36px; background-image: linear-gradient(to right, #aa004c, #bf1051, #d51e56, #ea2b59, #ff385c);'
        "
      />
      <v-row
        class="ma-0"
        justify="center"
        style="padding: 16px; color: white; font-weight: bold"
      >
        <base-title
          style="margin-top: auto; margin-bottom: auto !important"
          space="0"
          :title="$t('newsletter.title')"
        />
      </v-row>
      <v-row
        class="ma-0"
        justify="center"
        style="padding: 16px; color: white; font-weight: bold"
      >
      {{ $t("newsletter.subTitle") }}
      </v-row>
      <v-row
        justify="center"
        style="padding: 16px; color: white; font-weight: bold"
      >
        <v-form ref="form" v-model="valid" lazy-validation>
          <base-text-field
            hide-details
            :label="$t('newsletter.emailLabel')"
            :rules="emailRules"
            placeholder="john@example.com"
            required
            style="min-width: 280px"
            v-model="email"
          >
          </base-text-field>
        </v-form>
      </v-row>
      <base-btn
        :disabled="!this.valid"
        :style="!valid ? 'border: 0px solid white!important;' : ''"
        @click="subscribe"
        style="margin: auto; background-color: rgb(0 0 0 / 0%) !important"
      >
        {{ this.newsletterSignupBtnText }}
      </base-btn>
      <div
        class="triangle-bottomleft"
        :style="
          'border-bottom: ' +
          this.screenCalcTriangleBorderBottom +
          'px solid white; border-right: ' +
          this.screenWidth +
          'px solid transparent; margin-top: 36px;background-image: linear-gradient(to right, #aa004c, #bf1051, #d51e56, #ea2b59, #ff385c);'
        "
      />
    </base-section>
    <v-snackbar
      v-model="snackbar"
      style="z-index: 9999999999999999"
      :timeout="3000"
      color="#aa004c"
      rounded="pill"
    >
      {{ this.snackBarText }}
    </v-snackbar>
  </v-theme-provider>
</template>

<style scoped>
.triangle-bottomleft {
  width: 0;
  height: 0;
  border-bottom: 100px solid white;
  border-right: 100px solid transparent;
}
</style>

<script>
import axios from "axios";

export default {
  name: "SectionNewsletterAlt",
  data() {
    return {
      valid: false,
      snackBarText: "Signed up to newsletter :)",
      newsletterSignupBtnText: "$t('newsletter.subscribe')",
      snackbar: false,
      email: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid.",
      ],
    };
  },
  methods: {
    subscribe() {
      this.$refs.form.validate();
      console.log("validated");
      if (this.valid) {
        this.sendInquiry();
      }
    },
    sendInquiry() {
      let messageBody = "Newsletter email:" + this.email;
      axios
        .post(`https://v2.tentifly.com/inquiry`, {
          message: messageBody,
        })
        .then((response) => {
          this.snackbar = true;
          this.newsletterSignupBtnText = $t('newsletter.signedUp');
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
  },
  computed: {
    screenWidth: {
      get() {
        return this.$store.state.screenWidth;
      },
      set(newValue) {
        return this.$store.dispatch("setScreenWidth", newValue);
      },
    },
    screenCalcTriangleBorderBottom() {
      return (100 / 1680) * this.screenWidth; // 1050px for reference
    },
  },
};
</script>
