var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-theme-provider',{attrs:{"dark":""}},[_c('base-section',{staticClass:"primary",staticStyle:{"text-align":"center","padding-top":"0px !important","background-image":"linear-gradient(to right, #aa004c, #bf1051, #d51e56, #ea2b59, #ff385c)","background":"linear-gradient("},attrs:{"id":"contact-us","space":"36"}},[_c('div',{staticClass:"triangle-bottomleft",style:('border-bottom: ' +
        this.screenCalcTriangleBorderBottom +
        'px solid rgb(170, 0, 76, 0); border-right: ' +
        this.screenWidth +
        'px solid white; margin-bottom: 36px; background-image: linear-gradient(to right, #aa004c, #bf1051, #d51e56, #ea2b59, #ff385c);')}),_c('v-row',{staticClass:"ma-0",staticStyle:{"padding":"16px","color":"white","font-weight":"bold"},attrs:{"justify":"center"}},[_c('base-title',{staticStyle:{"margin-top":"auto","margin-bottom":"auto !important"},attrs:{"space":"0","title":_vm.$t('newsletter.title')}})],1),_c('v-row',{staticClass:"ma-0",staticStyle:{"padding":"16px","color":"white","font-weight":"bold"},attrs:{"justify":"center"}},[_vm._v(" "+_vm._s(_vm.$t("newsletter.subTitle"))+" ")]),_c('v-row',{staticStyle:{"padding":"16px","color":"white","font-weight":"bold"},attrs:{"justify":"center"}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('base-text-field',{staticStyle:{"min-width":"280px"},attrs:{"hide-details":"","label":_vm.$t('newsletter.emailLabel'),"rules":_vm.emailRules,"placeholder":"john@example.com","required":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1)],1),_c('base-btn',{staticStyle:{"margin":"auto","background-color":"rgb(0 0 0 / 0%) !important"},style:(!_vm.valid ? 'border: 0px solid white!important;' : ''),attrs:{"disabled":!this.valid},on:{"click":_vm.subscribe}},[_vm._v(" "+_vm._s(this.newsletterSignupBtnText)+" ")]),_c('div',{staticClass:"triangle-bottomleft",style:('border-bottom: ' +
        this.screenCalcTriangleBorderBottom +
        'px solid white; border-right: ' +
        this.screenWidth +
        'px solid transparent; margin-top: 36px;background-image: linear-gradient(to right, #aa004c, #bf1051, #d51e56, #ea2b59, #ff385c);')})],1),_c('v-snackbar',{staticStyle:{"z-index":"9999999999999999"},attrs:{"timeout":3000,"color":"#aa004c","rounded":"pill"},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(this.snackBarText)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }